<template>
  <div class="pageContol studentList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">考试管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">考试计划</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 10px;">
            <div title="考试计划编号" class="searchboxItem ci-full">
              <span class="itemLabel" style="width: 180px !important;">考试计划编号:</span>
              <el-input v-model="retrievalData.planNumber" type="text" size="small" placeholder="考试计划编号" clearable />
            </div>
            <div title="考试计划名称" class="searchboxItem ci-full">
              <span class="itemLabel" style="width: 180px !important;">考试计划名称:</span>
              <el-input v-model="retrievalData.planName" type="text" size="small" placeholder="考试计划名称" clearable />
            </div>
            <div title="操作项目" class="searchboxItem ci-full">
              <span class="itemLabel">操作项目:</span>
              <el-select clearable size="small" v-model="retrievalData.workCategory" placeholder="请选择">
                <el-option v-for="item in workCategoryList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <div title="审核状态" class="searchboxItem ci-full">
              <span class="itemLabel">审核状态:</span>
              <el-select clearable size="small" v-model="retrievalData.auditState" placeholder="请选择">
                <el-option v-for="item in auditStateList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox">
            <div title="考试日期" class="searchboxItem ci-full">
              <span class="itemLabel">考试日期:</span>
              <el-date-picker v-model="retrievalData.examinationDate" size="small" type="daterange" range-separator="至"
                start-placeholder="开始日期" end-placeholder="结束日期" value="yyyy-MM-dd" value-format="yyyy-MM-dd" />
            </div>
            <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" />
              <el-table-column label="考试计划编号" align="left" show-overflow-tooltip prop="planNumber" minWidth="100" />
              <el-table-column label="考试计划名称" align="left" show-overflow-tooltip prop="planName" minWidth="100" />
              <el-table-column label="考试日期" align="left" show-overflow-tooltip prop="examinationDate" minWidth="100" />
              <el-table-column label="考点名称" align="left" show-overflow-tooltip prop="centerName" minWidth="100" />
              <el-table-column label="操作项目" align="left" show-overflow-tooltip prop="workCategory" minWidth="100">
                <template slot-scope="scope">
                  {{ scope.row.workCategory ? $setDictionary("PA_WORK_CATEGORY", scope.row.workCategory) : "" }}
                </template>
              </el-table-column>
              <el-table-column label="题库名称" align="left" show-overflow-tooltip prop="bankName" minWidth="100" />
              <el-table-column label="审核结果" align="left" show-overflow-tooltip prop="auditState" minWidth="100">
                <template slot-scope="scope">
                  {{ scope.row.auditState ? $setDictionary("PA_EXAMINATION_STATE", scope.row.auditState) : "" }}
                </template>
              </el-table-column>
              <el-table-column label="创建时间" align="left" show-overflow-tooltip prop="createTime" width="140px" />
              <el-table-column label="操作" align="center" width="160px" fixed="right">
                <template slot-scope="scope">
                  <!-- auditState: 00 待提交 10 未审核 20 通过 30 驳回 -->
                  <el-button type="text" style="padding: 0px 5px" size="mini" @click="to_examine(scope.row)"
                    :disabled="['00', '20', '30'].includes(scope.row.auditState)">审核</el-button>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "examinationAffairsManage_set_examPlan",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 检索数据
      retrievalData: {
        planNumber: "", // 考试计划编号
        planName: "", // 考试计划名称
        workCategory: "", // 操作项目
        auditState: "", // 审核状态
        examinationDate: "", // 考试日期
      },
      workCategoryList: [], // 操作项目 - 下拉数据
      auditStateList: [], // 审核状态 - 下拉数据
    };
  },
  created() {
    this.getDictionary();
  },
  watch: {},
  computed: {},
  mounted() { },
  methods: {
    // 获取 - 字典
    getDictionary() {
      // 操作项目
      let workCategory = this.$setDictionary("PA_WORK_CATEGORY", "list");
      for (const key in workCategory) {
        this.workCategoryList.push({
          value: key,
          label: workCategory[key],
        });
      }
      // 审核状态
      let auditState = this.$setDictionary("PA_AFFILIATION_STATE", "list");
      for (const key in auditState) {
        this.auditStateList.push({
          value: key,
          label: auditState[key],
        });
      }
    },
    // 获取 - 列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        ...this.retrievalData
      };
      if (this.startDate && this.startDate.length > 0) {
        params.startDateStart = this.startDate[0];
        params.startDateEnd = this.startDate[1];
      }
      if (this.retrievalData.examinationDate) {
        params.examinationDateStart = this.retrievalData.examinationDate[0];
        params.examinationDateEnd = this.retrievalData.examinationDate[1];
      }
      this.doFetch({
        url: "/biz/plan/audit/pageList",
        params,
        pageNum,
      }, true, 10);
    },
    // 去审核
    to_examine(row) {
      this.$router.push({
        path: "/practicalAssessmentSystem/examinationAffairsManage/examPlan_info",
        query: {
          examinationId: row?.examinationId ?? '',
          operateType: 'examine', // 审核
        }
      })
    },
  },
};
</script>
<style lang="less" scoped></style>